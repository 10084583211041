* {
  font-family: 'Heebo', sans-serif !important;
  direction: rtl;
}

.Header {
  background-color: black;
  width: 100%;
}

.Data {
  background-color: #DBDBDB;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.aboutas {
  width: 80%;
  text-align: justify;
  display: flex;
  align-items: center;
  flex-direction: column;
}


.BoxArea {
  background-color: #c4c4c4;
  width: 100%;
  height: 500pt;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
  align-items: center;
}

h1 {
  font-weight: bolder;
  color: #9f7f45;
  font-size: "40pt";
  margin: "20px 0";
}

h3 {
  color: #9f7f45;
}

.BoxArea a {
  width: 45%;
}

.Box {
  background-color: rgb(239, 238, 248);
  width: 100%;
  height: 200pt;
}

.Carousel {
  height: 270px;
}

.Contact {
  background-color: #c4c4c4;
  height: 250pt;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.form {
  width: 80%;
}

.form button {
  width: 80%;
  margin: 0 auto;
}

.Footer {
  background-color: #DBDBDB;
  width: 100%;
  height: 200pt;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer a svg {
  margin: 10px;
  color: black !important;
}

.Article {
  width: 80%;
  display: flex;
  margin: 3%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.Article ul li {
  font-size: 17pt;
}

.Article a {
  width: 100%;
  margin: 30pt 0pt;
}

.flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

li {
  list-style-type: none;
  background-image: url('/src/photos/elementHeaders.png');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 20px 20px; 
  padding-right: 35px; 
  line-height: 28pt;
}

@media (max-width: 767px) {
  .BoxArea {
    background-color: #c4c4c4;
    width: 100%;
    height: auto;
    display: block;
}


.Article ul li {
  margin-bottom: 10pt;
}

/* .BoxArea .boxpadding {
  padding-bottom: 13pt;
} */
 
.projectPic img{
  margin-bottom: 5pt;
}
}